import React, { useEffect } from "react";

import {
  SelectProductsInlineSection,
  SelectProductsSection,
} from "../sections/SelectProductSection";
import {
  TargetDueDateInlineSection,
  TargetDueDateSection,
} from "../sections/TargetDueDateSection";
import {
  ProjectInstructionsInlineSection,
  ProjectInstructionsSection,
} from "../sections/ProjectInstructionsSection";
import {
  AdditionalInformationInlineSection,
  AdditionalInformationSection,
  useAdditionalInformation,
} from "../sections/AdditionalInformationSection";
import { useProjectInstructions } from "../fields/ProjectInstructions";
import { useSelectProducts } from "../fields/SelectProducts";
import { useFileAttachmentUpload } from "../../../reusable/fileAttachmentUpload/useFileAttachmentUpload";
import {
  FileAttachmentUploadInlineSection,
  FileAttachmentUploadSection,
} from "../sections/FileAttachmentUploadSection";
import { useDatePicker } from "../../../reusable/datepicker/useDatepicker";
import {
  IsFreight,
  SelectOrderInlineSection,
  SelectOrderSection
} from "../sections/SelectOrderSection";
import { useSelectOrders } from "../fields/useSelectOrders";
import { useSelectRestocks } from "../fields/useSelectRestocks";
import { useSelectInbounds } from "../fields/useSelectInbounds";
import SelectRestockSection, {
  SelectRestockInlineSection,
} from "../sections/SelectRestocksSection";
import SelectInboundSection, {
  SelectInboundInlineSection,
} from "../sections/SelectInboundSection";
import { InputInstructionsInlineSection, InputInstructionsSection } from "../sections/InputInstructionsSection";
import { OutputInstructionsInlineSection, OutputInstructionsSection } from "../sections/OutputInstructionsSection";
import { useInputInstruction } from "../fields/InputInstructionsField";
import { useOutputInstruction } from "../fields/OutputInstructionsField";
import {
  SelectWarehouseInlineSection,
  SelectWarehouseSection,
} from "../sections/SelectWarehouseSection";
import { useSelectWarehouse } from "../../../reusable/SelectWarehouse";
import { TWO_BUSINESS_DAYS_AFTER } from "../../../../utils/general";
import { useProjectBilling, useProjectCharges } from "./ProjectBillingInformation";

const PROJECT_REQUEST_STATE = {
  uuid: null,
  name: null,
  status: null,
  createdAt: null,
  completedDate: null,
  estimatedLaborHours: null,
  isLoading: true,
};



export const useProjectRequestForm = (projectRequestFormState = {}) => {
  const [projectRequestState, setProjectRequestState] = React.useState(
    PROJECT_REQUEST_STATE
  );

  const [originalState, setOriginalState] = React.useState(projectRequestFormState);

  const selectProductState = useSelectProducts(projectRequestFormState);
  const dueDateState = useDatePicker(projectRequestFormState.dueDateState || { minDate: TWO_BUSINESS_DAYS_AFTER });
  const projectInstructionsState = useProjectInstructions(projectRequestFormState.projectInstructionsState);
  const fileAttachmentUploadState = useFileAttachmentUpload(projectRequestFormState.fileAttachmentUploadState);
  const additionalInformationState = useAdditionalInformation(projectRequestFormState.additionalInformationState);
  const selectOrdersState = useSelectOrders(projectRequestFormState.selectOrdersState);
  const selectRestocksState = useSelectRestocks(projectRequestFormState.selectRestocksState);
  const selectInboundsState = useSelectInbounds(projectRequestFormState.selectInboundsState);
  const inputInstructionsState = useInputInstruction(projectRequestFormState.inputInstructionsState?.variantsState);
  const outputInstructionsState = useOutputInstruction(projectRequestFormState.outputInstructionsState?.variantsState);
  const selectWarehouseState = useSelectWarehouse(
    projectRequestFormState.selectWarehouseState
  );
  const chargesState = useProjectCharges();
  const billingState = useProjectBilling(
    projectRequestFormState.billing
  );

  const resetWarehouse = () => {
    selectWarehouseState.setCurrentItem({});
  }

  return {
    projectRequestState,
    setProjectRequestState,
    originalState,
    setOriginalState,
    selectProductState,
    dueDateState,
    projectInstructionsState,
    fileAttachmentUploadState,
    additionalInformationState,
    selectOrdersState,
    selectRestocksState,
    selectInboundsState,
    inputInstructionsState,
    outputInstructionsState,
    selectWarehouseState,
    chargesState,
    billingState,
    resetWarehouse,
  };
};

export const ProjectRequestForm = (props) => {
  const {
    projectRequestType,
    projectInstructionsState,
    dueDateState,
    fileAttachmentUploadState,
    selectProductState,
    selectOrdersState,
    selectRestocksState,
    selectInboundsState,
    selectWarehouseState,
    additionalInformationState,
    inputInstructionsState,
    outputInstructionsState,
    store,
    productFilters,
    isInline,
    isFreight,
    setIsFreight,
    originalState,
  } = props;

  const {
    hasProduct,
    hasDueDate,
    hasInstruction,
    hasFileAttachmentUpload,
    hasPhotoRequired,
    hasQuoteRequired,
    hasOrders,
    hasRestocks,
    hasInbounds,
    hasInput,
    hasOutput,
    hasWarehouses,
    inputDescription,
    outputDescription,
  } = projectRequestType;

  const hasAdditional = hasPhotoRequired || hasQuoteRequired;

  const isOrdersEnabled = props.hasOrders !== false && hasOrders;
  const isFreightEnabled = props.hasIsFreight === true && hasOrders;
  const isRestocksEnabled = props.hasRestocks !== false && hasRestocks;
  const isInboundsEnabled = props.hasInbounds !== false && hasInbounds;
  const isWarehouseEnabled = props.hasWarehouse !== false && hasWarehouses;
  const { orders } = selectOrdersState;
  const { restocks } = selectRestocksState;
  const { inbounds } = selectInboundsState;


  const showWarehouseSelection = store?.warehouses?.length > 1 || (originalState.warehouse &&
    !store?.warehouses?.some((w) => w.uuid === originalState.warehouse.uuid));
  const productSearchFilter = {
    ...productFilters,
    orders,
    restocks,
    inbounds,
  };

  const inputProductSearchFilter = {
    restocks,
    inbounds,
  };

  useEffect(() => {
    if (store && store.warehouses && store.warehouses.length == 1) {
      selectWarehouseState.setCurrentItem(store.warehouses[0]);
    }
  }, [store]);

  const AdditionalInformation = isInline
    ? AdditionalInformationInlineSection
    : AdditionalInformationSection;
  const SelectOrder = isInline ? SelectOrderInlineSection : SelectOrderSection;
  const SelectRestock = isInline ? SelectRestockInlineSection : SelectRestockSection;
  const SelectInbound = isInline ? SelectInboundInlineSection : SelectInboundSection;
  const SelectWarehouse = isInline ? SelectWarehouseInlineSection : SelectWarehouseSection;
  const SelectProducts = isInline ? SelectProductsInlineSection : SelectProductsSection;
  const TargetDueDate = isInline ? TargetDueDateInlineSection : TargetDueDateSection;
  const ProjectInstructions = isInline
    ? ProjectInstructionsInlineSection
    : ProjectInstructionsSection;

  const InputInstructions = isInline ? InputInstructionsInlineSection : InputInstructionsSection;

  const OutputInstructions = isInline ? OutputInstructionsInlineSection : OutputInstructionsSection;

  const FileAttachmentUpload = isInline
    ? FileAttachmentUploadInlineSection
    : FileAttachmentUploadSection;

  const onSelectRestock = (restock) => {
    const projectWarehouse = selectWarehouseState.currentItem;
    if (!projectWarehouse?.uuid) {
      const restockWarehouseId = restock.fulfillments.map((f) => f.warehouse_id).find((w) => w);
      const restockWarehouse = store.warehouses.find((w) => (w.uuid = restockWarehouseId));
      selectWarehouseState.setCurrentItem(restockWarehouse);
    }
  };

  const onSelectInbound = (inbound) => {
    const projectWarehouse = selectWarehouseState.currentItem;
    if (!projectWarehouse?.uuid && inbound.warehouse) {
      selectWarehouseState.setCurrentItem(inbound.warehouse);
    }
  };

  const restockFilters = { warehouseId: selectWarehouseState.currentItem?.uuid };

  return (
    <>
      {(isInboundsEnabled || isRestocksEnabled || isWarehouseEnabled) &&
        showWarehouseSelection && (
          <SelectWarehouse
            {...selectWarehouseState}
            readOnly={selectRestocksState.restocks.length > 0}
            items={store.warehouses}
          />
        )}
      {isOrdersEnabled && <SelectOrder
        {...selectOrdersState}
        store={store}
        isFreight={isFreight}
        setIsFreight={setIsFreight}
      />}
      {isFreightEnabled && <IsFreight isFreight={isFreight} setIsFreight={setIsFreight} />}
      {isRestocksEnabled && (
        <SelectRestock
          {...selectRestocksState}
          filters={restockFilters}
          onSelectRestock={onSelectRestock}
          store={store}
        />
      )}
      {isInboundsEnabled && (
        <SelectInbound {...selectInboundsState} onSelectInbound={onSelectInbound} store={store} />
      )}
      {hasProduct && (
        <SelectProducts store={store} {...selectProductState} filters={productSearchFilter} />
      )}
      {hasInput && (
        <InputInstructions
          store={store}
          {...inputInstructionsState}
          filters={inputProductSearchFilter}
          description={inputDescription}
        />
      )}
      {hasOutput && (
        <OutputInstructions
          store={store}
          {...outputInstructionsState}
          description={outputDescription}
        />
      )}
      {hasInstruction && <ProjectInstructions {...projectInstructionsState} />}
      {hasDueDate && <TargetDueDate {...dueDateState} />}
      {hasFileAttachmentUpload && <FileAttachmentUpload {...fileAttachmentUploadState} />}
      {hasAdditional && (
        <AdditionalInformation
          {...additionalInformationState}
          hasPhotoRequired={hasPhotoRequired}
          hasQuoteRequired={hasQuoteRequired}
        />
      )}
    </>
  );
};

export default ProjectRequestForm;
