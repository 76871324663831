import React from "react";
import { usePageState } from "../../reusable/page/usePageState";
import { useProjectRequestForm } from "./components/ProjectRequestForm";

export const DEFAULT_EDIT_PROJECT_REQUEST_PAGE_STATE = {
  projectRequestType: {},
  storeId: null,
};

export const useEditProjectRequestPage = (editProjectRequestPageState = {}) => {
  const [editPageState, setEditPageState] = React.useState(() => ({
    ...DEFAULT_EDIT_PROJECT_REQUEST_PAGE_STATE,
    ...editProjectRequestPageState,
  }));

  const {
    state,
    loadState: loadPageState,
    setIsLoading,
    setIsSaving,
    setIsEditing,
  } = usePageState({ isLoading: true });
  const formState = useProjectRequestForm(state.formState);
  const {
    projectRequestTypeState,
    selectProductState,
    dueDateState,
    projectInstructionsState,
    fileAttachmentUploadState,
    additionalInformationState,
    inputInstructionsState,
    outputInstructionsState,
  } = formState;

  const setProjectRequestType = (projectRequestType) => {
    setEditPageState((prevState) => ({
      ...prevState,
      projectRequestType,
    }));
  };

  const onSaveProjectRequestChanges = () => {};

  const loadProjectRequest = (projectRequest) => {
    const {
      uuid,
      name,
      status,
      createdAt,
      completedDate,
      variants,
      orders,
      restocks,
      inbounds,
      estimatedLaborHours,
      fileAttachmentUpload,
      isPhotoRequired,
      isQuoteRequired,
      storeId,
      inputVariants,
      outputVariants,
      warehouse,
      billing,
      charges,
    } = projectRequest;
    formState.setProjectRequestState({
      uuid,
      name,
      completedDate,
      status,
      createdAt,
      estimatedLaborHours,
    });
    formState.setOriginalState(projectRequest);
    formState.selectProductState.loadVariants(variants);
    formState.selectOrdersState.loadOrders(orders);
    formState.selectRestocksState.loadRestocks(restocks);
    formState.selectInboundsState.loadInbounds(inbounds);
    formState.selectWarehouseState.setCurrentItem(warehouse);
    formState.fileAttachmentUploadState.loadItems(fileAttachmentUpload);
    formState.chargesState.setChargeState(charges);
    formState.billingState.setProjectBillingState(billing);
    dueDateState.setDate(projectRequest.dueDate);
    projectInstructionsState.setInstructions(projectRequest.instructions);
    inputInstructionsState.loadInputInstructions(inputVariants);
    outputInstructionsState.loadOutputInstructions(outputVariants);
    additionalInformationState.loadState({ isPhotoRequired, isQuoteRequired });
    setProjectRequestType(projectRequest.projectRequestType);
    setIsLoading(false);
    setEditPageState(prevState => ({
      ...prevState,
      storeId,
    }))
  };

  return {
    state,
    ...editPageState,
    formState,
    setIsLoading,
    setIsSaving,
    setIsEditing,
    loadProjectRequest,
  };
};
