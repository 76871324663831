export const DEFAULT_TITLE = 'Default Title';
const AIRHOUSE = 'airhouse';
const SHOPIFY = 'shopify';
const AIRHOUSE_EDI = 'orderful';
const SQUARESPACE = 'squarespace';
const ECOMM_PARTNERS = [SHOPIFY, SQUARESPACE];

const STATUS_NEW = 'NEW';
const STATUS_AUTHORIZED = 'AUTHORIZED';
const STATUS_DEMO = 'DEMO';
const STATUS_APP_INSTALLED = 'APP_INSTALLED';
const STATUS_APP_UNINSTALLED = 'APP_UNINSTALLED';
const STATUS_SYNCHRONIZED = 'SYNCHRONIZED';
const STATUS_ERROR = 'ERROR';
const STATUS_CANCELLED = 'CANCELLED';
const STATUS_DRAFT = 'DRAFT';
const STATUS_CREATED = 'CREATED';
const STATUS_PAID = 'PAID';
const STATUS_PROCESSED = 'PROCESSED';
const STATUS_PUBLISHED = 'PUBLISHED';
const STATUS_DELIVERED = 'Delivered';
const PROCESS_SHIP = 'SHIP';
const PROCESS_LOST = 'LOST';
const PROCESS_READ = 'READ';
const EDI_DRAFT = "EDI_DRAFT";

export const SHOP_FULFILLMENT_STATUS_FULFILLED = 'fulfilled';
export const COMBINED_STATUS_DEFAULT = 'processing'
export const COMBINED_STATUS_PARTIAL_DEFAULT = 'partial'

export const INVOICE_DISPLAY_STATUS_UPCOMING = "UPCOMING";
export const INVOICE_DISPLAY_STATUS_PAID = "PAID";
export const INVOICE_DISPLAY_STATUS_UNPAID = "UNPAID";
export const INVOICE_DISPLAY_STATUS_PARTIALLY_PAID = "PARTIALLY_PAID";
export const INVOICE_DISPLAY_STATUS_OVERDUE = "OVERDUE";
export const INVOICE_DISPLAY_STATUS_PAYMENT_ERROR = "PAYMENT_ERROR";
export const INVOICE_DISPLAY_STATUS_BAD_DEBT = "BAD_DEBT";
export const INVOICE_DISPLAY_STATUS_PROCESSING = "PROCESSING";
export const INVOICE_DISPLAY_STATUS_MANUAL_BILLING = "MANUAL_BILLING";

export const PROJECT_CATEGORY = "PROJECT";

export const PAYMENT_METHOD_CREDIT_CARD = "creditCard";
export const PAYMENT_METHOD_BANK_ACCOUNT = "achDebit";
export const INVOICE_SUSPENSION = 15;

export const LOT_NUMBER_CHARS_LIMIT = 64;

export const INVOICE_FULFILLMENT_ITEM_LINE_ITEM_PREFIXES = [
  "label_cost",
  "charge",
  "fee",
  "surcharge",
  "adjustment",
]

export const ACCEPTED_IMAGE_FILE_MIME_TYPES = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/gif',
  'image/svg+xml',
];

const ACCEPTED_IMAGE_EXTENSIONS = [
  '.png',
  '.jpeg',
  '.jpg',
]

export const ACCEPTED_IMAGE_TYPES =
  ACCEPTED_IMAGE_FILE_MIME_TYPES.concat(ACCEPTED_IMAGE_EXTENSIONS);


const ACCEPTED_DOCUMENT_FILE_MIME_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

const ACCEPTED_DOCUMENT_EXTENSIONS = ['.doc', '.docx', '.csv', '.xls', '.xlsx'];

export const ACCEPTED_DOCUMENT_TYPES = ACCEPTED_DOCUMENT_FILE_MIME_TYPES.concat(
  ACCEPTED_DOCUMENT_EXTENSIONS
);

const ACCEPTED_CSV_MIME_TYPES = ['text/csv'];
const ACCEPTED_CSV_EXTENSIONS = ['.csv', 'text/csv'];

export const ACCEPTED_CSV_TYPES = ACCEPTED_CSV_MIME_TYPES.concat(ACCEPTED_CSV_EXTENSIONS);

export const ACCEPTED_UPLOAD_FILE_TYPES =
  ACCEPTED_IMAGE_TYPES.concat(ACCEPTED_DOCUMENT_TYPES).concat(ACCEPTED_CSV_TYPES);

export default {
  DEFAULT_TITLE,
  AIRHOUSE,
  STATUS_DEMO,
  STATUS_APP_INSTALLED,
  STATUS_APP_UNINSTALLED,
  STATUS_ERROR,
  PROCESS_SHIP,
  PROCESS_LOST,
  PROCESS_READ,
  STATUS_CANCELLED,
  STATUS_DRAFT,
  STATUS_PAID,
  STATUS_PUBLISHED,
  STATUS_NEW,
  STATUS_DELIVERED,
  STATUS_PROCESSED,
  SHOPIFY,
  AIRHOUSE_EDI,
  SQUARESPACE,
  ECOMM_PARTNERS,
  EDI_DRAFT,
  STATUS_CREATED,
  LOT_NUMBER_CHARS_LIMIT,
}
